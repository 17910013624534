import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faEnvelope } from "@fortawesome/pro-light-svg-icons";
import Layout from "../components/Layout";
import SidebarAccount from "../components/SidebarAccount";
import { regionRequest } from "actions";

const Help = (props) => {
  const {
    dispatch,
    region_data: { unique_region_text },
  } = props;
  const { language } = useI18next();
  const { i18n } = useTranslation();

  useEffect(() => {
    document.dir = i18n.dir();
    dispatch(regionRequest(language));
  }, [dispatch, language, i18n]);

  return (
    <Layout pageName="help">
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <main
        className={`section-container help-container ${
          language === "ar" ? "custom-rtl-dir" : ""
        }`}
      >
        <header id="documentation-and-help">
          <h1 className="h2">
            <FontAwesomeIcon className="mr-3" icon={faQuestionCircle} />
            <Trans i18nKey="helpTitle">Documentation and Help</Trans>
          </h1>
        </header>
        <section className="help-section" id="contact">
          <h2>
            <FontAwesomeIcon className="mr-3" icon={faEnvelope} />
            <Trans i18nKey="contactTitle">Contact</Trans>
          </h2>
          <p>
            <Trans i18nKey="helpEmailLabel">Help Desk:</Trans>{" "}
            <a href="mailto:geoguard-help@element84.com">
              geoguard-help@element84.com
            </a>
            <br />
            <Trans i18nKey="moreInfoEmailLabel">
              For More Information:
            </Trans>{" "}
            <a href="mailto:dppa-innovation@un.org">dppa-innovation@un.org</a>
          </p>
        </section>
      </main>
      <SidebarAccount {...props} />
    </Layout>
  );
};

export default connect((state) => state)(Help);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
